import { Component } from "react"
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI


    
 
class RecomendadosSkipeCmp extends Component {    

    render() {
        const {            
            titleIcon=<span><strong>TÉRMINOS Y CONDICIONES</strong><br/>Programa Skip-A-Payment (Recorrer fecha de pago)</span>,
            txtTitle=<span><strong>Objetivo y Descripción del Programa</strong><br/><br/>Apoyo Financiero, Inc. (AFI) ha establecido el Programa Skip-A-Payment (el “Programa”) según el cual los clientes de préstamos existentes de AFI que califiquen bajo el Programa tendrán la opción de recorrer la fecha de pago de un préstamo mensual o quincenal programado regularmente en sus préstamos con AFI, y uno o más pagos programados subsecuentes en intervalos designados, conforme y sujetos a los términos y condiciones del Programa. Los clientes que califican incluyen aquellos que han hecho consistentemente sus pagos de los últimos seis (6) meses a tiempo. El objetivo del Programa es proporcionar a los clientes de préstamos de AFI que califiquen bajo el Programa un incentivo para desarrollar y mantener buenas prácticas de administración de crédito durante la vida de cada préstamo de AFI.<br/><br/></span>,
            txtContent=<span><strong>Cómo funciona el Programa</strong><br/></span>,
            txtTitleEmp='',
            txtContentValors=<span>
                                  Cada cliente de préstamo y co-prestatario, si lo hubiera, que califique bajo el Programa tiene la oportunidad de recorrer la fecha de pago del préstamo sujeto a los siguientes términos y condiciones y a cualquier cambio hecho por AFI al programa:
                                  <br/><br/>
                                  <ol type="A">
                                      <li>Cada cliente calificado debe completar y firmar una Solicitud para el Programa Skip-A-Payment de AFI en el que se identifique el préstamo en cuestión y un único pago de préstamo cuya fecha de vencimiento será recorrida, y AFI debe aceptarlo por escrito por lo menos cinco (5) días hábiles antes de la fecha de vencimiento del primer pago que el cliente propone sea recorrida para que el préstamo del cliente sea considerado para su participación en el programa.<br/><br/></li>
                                      <li>Bajo el Programa, un cliente calificado puede solicitar recorrer la fecha de un pago de un préstamo calificado una vez cada seis (6) meses durante el término del préstamo y, si es aprobado, sólo es válido para un (1) pago programado que venza en cualquier período de seis (6) meses durante el término del préstamo. Sólo se puede hacer y considerar una solicitud de recorrer la fecha de un pago por parte de AFI durante cualquiera de dichos seis (6) meses.<br/><br/></li>
                                      <li>El préstamo no puede estar vencido por más de 15 días a partir de la fecha de vencimiento del pago cuya fecha será recorrida.<br/><br/></li>
                                      <li>Se seguirán acumulando y debiendo intereses a AFI sobre el saldo de capital no pagado del préstamo durante el mes en que se recorra la fecha de el pago programado y posteriormente hasta que el saldo del capital no pagado del préstamo y todos los intereses acumulados no pagados se paguen en su totalidad a AFI.<br/><br/></li>
                                      <li>Si AFI aprueba la Solicitud para el Programa Skip-A-Payment de un cliente para recorrer la fecha de un pago, y el pago se recorre, el plazo del préstamo NO se extenderá y los pagos subsiguientes debidos en virtud del préstamo no se aplazarán ni se pospondrán (excepto un pago posterior cuya fecha será recorrida de conformidad con el Programa). El saldo de capital restante del préstamo, todos los intereses acumulados no pagados sobre el mismo y todos los cargos por mora no pagados vencerán y serán pagaderos en la fecha de vencimiento original del préstamo, con sujeción a la aceleración del vencimiento en caso de que se produzca un incumplimiento de pago en el marco del préstamo. Todas las demás condiciones del préstamo seguirán siendo las mismas.<br/><br/></li>
                                      <li>Si una sucursal de AFI no recibe oportunamente una Solicitud para el Programa Skip-A-Payment para recorrer la fecha de un pago, AFI podrá aplicar un recargo por demora si ese pago no se efectúa dentro del período de gracia aplicable conforme al contrato de préstamo vigente del solicitante.<br/><br/></li>
                                      <li>AFI no cobrará ninguna tarifa por procesar cada Solicitud para el Programa Skip-A-Payment.<br/><br/></li>
                                      <li>AFI se reserva el derecho de rechazar cualquier Solicitud para el Programa Skip-A-Payment (para recorrer la fecha de un pago), independientemente de que dicha solicitud cumpla o no los requisitos mínimos para participar en el programa.<br/></li>
                                 </ol>
                             </span>,
            txtContentOne=<span><strong>Cambios que Afectan al Programa</strong><br/><br/>Los cambios y adiciones al Programa, incluyendo cualquier suspensión, modificación o terminación al Programa, podrán ser realizados por AFI en cualquier momento tras la publicación por parte de AFI de cualquier cambio o terminación de este tipo en su sitio web (https://www.apoyo-financiero.com) y serán efectivos según lo dispuesto en dicha publicación (o si no se menciona una fecha y hora efectiva en dicha publicación, entonces en el momento en que se realice dicha publicación), y se aplicarán a todos los prestatarios existentes y futuros que sean participantes o que hayan solicitado participar en el Programa a partir de dicha fecha y hora efectiva.</span>,                               
            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h4" >
                        <strong style={{color:'#698d21'}}>{titleIcon}</strong>                        
                        </Typography>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:'#e66728'}}>{txtTitleEmp}</strong>                        
                        </Typography>
                        
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/>
                     <Typography component='subtitle' variant="subtitle" >
                        {txtContentValors}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentOne}                        
                    </Typography>
                    <br/>                    
                    </CardElevation>
                    <br/><br/>
                    
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

RecomendadosSkipeCmp.propTypes = {      
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtContent: PropTypes.string, 
    txtTitleEmp: PropTypes.string,
    txtContentValors: PropTypes.string,
    txtContentOne: PropTypes.string, 
    txtContentTwo: PropTypes.string, 
        
}

export default withThemeProps(RecomendadosSkipeCmp, 'VDPNRecomendadosSkipe')






